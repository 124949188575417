<wndrc-modal-wrapper
	[title]="isSecondaryEmail() ? 'Confirm Secondary Email' : 'Confirm Email'"
	[showCloseButton]="isCloseButtonShown()"
>
	@if (isLogoutButtonShown()) {
		<button
			class="logout-button"
			slot="secondary-button"
			type="button"
			(click)="onLogoutButtonClick()"
		>
			<ion-icon
				icon="log-out-outline"
				class="logout-icon"
			/>
		</button>
	}
	<div class="confirm">
		<p class="confirm__title">
			Check your {{ isSecondaryEmail() ? "secondary" : "" }} email address and enter code to confirm email
		</p>
		<wndrc-form-error-wrapper>
			<form
				[formGroup]="confirmationForm"
				(ngSubmit)="onSubmit()"
				[id]="formId"
			>
				<wndrc-input-error-wrapper>
					<wndrc-input
						clearInput
						placeholder="Code"
						[mask]="confirmationCodeMask"
						[formControl]="confirmationForm.controls.code"
					/>
				</wndrc-input-error-wrapper>
			</form>
		</wndrc-form-error-wrapper>

		<div class="confirm__resend">
			<p class="confirm__resend-text">
				@if (isTimerCompleted$ | async) {
					Didn’t receive a code?
					<button
						type="button"
						class="confirm__resend-button"
						(click)="onResendButtonClick()"
					>
						Resend
					</button>
				} @else {
					Didn’t receive a code? Try again after
					<span class="confirm__resend-button">
						{{ timer$ | async | wndrcTime }}
					</span>
				}
			</p>
		</div>
	</div>

	<button
		slot="bottom"
		type="submit"
		[attr.form]="formId"
		wndrcButton
		[wndrcLoading]="isLoading()"
	>
		Submit
	</button>
</wndrc-modal-wrapper>
